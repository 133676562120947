import { H1, SubmitButton, YStack } from '@mps/ui'

import { SchemaForm, formFields } from '@mps/pro-app/utils/SchemaForm'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

const LoginSchema = z.object({
  email: formFields.text.email('L’adresse email est invalide').describe(' // Adresse e-mail'), // .required('Veuillez renseigner votre adresse email'),
  password: formFields.text.describe(' // Mot de passe'),
})

type SignInFormProps = {
  onSubmit: (data: { email: string; password: string }) => Promise<void>
  isSubmitting?: boolean
  submitLabel?: string
}

export const SignInForm = ({ onSubmit, submitLabel = 'Se connecter' }: SignInFormProps) => {
  const form = useForm<z.infer<typeof LoginSchema>>()

  return (
    <YStack space="$4" width="100%">
      <FormProvider {...form}>
        <SchemaForm
          form={form}
          schema={LoginSchema}
          props={{
            email: {
              // size: "$5",
            },
            password: {
              // size: "$5",
              secureTextEntry: true,
            },
          }}
          onSubmit={onSubmit}
          renderAfter={({ submit }) => {
            return (
              <SubmitButton onPress={() => submit()} width="100%">
                {submitLabel}
              </SubmitButton>
            )
          }}
        >
          {(fields) => (
            <YStack key="email-box" ai="center" space="$4" minWidth={310} maxWidth={330}>
              <H1 textAlign="center">Content de vous revoir !</H1>
              <YStack space="$4" w="100%">
                {Object.values(fields)}
              </YStack>
            </YStack>
          )}
        </SchemaForm>
      </FormProvider>
    </YStack>
  )
}

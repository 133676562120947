import { useFieldInfo, useTsController } from '@ts-react/form'
import { ReactNode, useId, useRef } from 'react'
import {
  Fieldset,
  Label,
  Switch,
  SwitchProps,
  Text,
  Theme,
  XStack,
  isWeb,
  useThemeName,
} from 'tamagui'
import { FieldError } from '../FieldError'

type BooleanSwitchFieldProps = Pick<SwitchProps, 'size' | 'native'> & {
  label?: string | ReactNode
  labelProps?: any
}

export const BooleanSwitchField = (props: BooleanSwitchFieldProps) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<boolean>()
  const { label, isOptional } = useFieldInfo()
  const { label: labelParam, labelProps = {} } = props

  const id = useId()
  const themeName = useThemeName()
  const disabled = isSubmitting
  const switchRef = useRef(field.ref)

  return (
    <Theme name={error ? 'red' : themeName} forceClassName>
      <Fieldset ai="flex-start">
        {!!label && (
          <Label theme="alt1" size={props.size || '$3'} htmlFor={id}>
            {label} {isOptional && '(Optional)'}
          </Label>
        )}

        <XStack space="$3" ai="center">
          <Switch
            disabled={disabled}
            native
            checked={field.value}
            onCheckedChange={(checked) => field.onChange(checked)}
            animation="quick"
            hoverStyle={{ cursor: 'pointer', borderColor: '$borderColorHover' }}
            outlineWidth={0}
            bc={field.value === true ? '$color5' : error ? '$red4Light' : 'white'}
            borderWidth={1}
            {...(error ? { borderColor: '$red9Light' } : { borderColor: '$borderColor' })}
            ref={switchRef as any}
            id={id}
            size={isWeb ? '$2' : undefined}
            {...props}
          >
            <Switch.Thumb
              animation="bouncy"
              hoverStyle={{ bg: '$backgroundHover' }}
              mt={1}
              ml={1}
            />
          </Switch>

          {!!labelParam && (
            <Text
              flex={1}
              onPress={(e) => {
                if (typeof labelParam === 'string') {
                  console.log('clicking on label', switchRef)
                  ;(switchRef?.current as any)?.click()
                } else {
                  e.preventDefault()
                }
              }}
            >
              {typeof labelParam === 'string' ? (
                <Label size="$2" lineHeight="$3" {...labelProps}>
                  {labelParam}
                </Label>
              ) : (
                labelParam
              )}
            </Text>
          )}
        </XStack>

        {/* <FieldError message={error?.errorMessage} /> */}
      </Fieldset>
    </Theme>
  )
}

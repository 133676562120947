import { useFieldInfo, useTsController } from '@ts-react/form'
import { useId } from 'react'
import { Fieldset, Label, TextArea, TextAreaProps, Theme, useThemeName } from 'tamagui'

import { FieldError } from '../FieldError'

export const TextAreaField = (props: Pick<TextAreaProps, 'size' | 'autoFocus'>) => {
  console.log('TextAreaField', props)
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>()
  const { label, isOptional, placeholder } = useFieldInfo()
  const themeName = useThemeName()
  const id = useId()
  const disabled = isSubmitting

  return (
    <Theme name={error ? 'red' : themeName} forceClassName>
      <Fieldset>
        {!!label && (
          <Label theme="alt1" size={props.size || '$3'} htmlFor={id}>
            {label} {isOptional && '(Optional)'}
          </Label>
        )}
        <TextArea
          disabled={disabled}
          placeholderTextColor="$color10"
          value={field.value}
          onChangeText={(text) => field.onChange(text)}
          onBlur={field.onBlur}
          ref={field.ref}
          placeholder={placeholder}
          id={id}
          rows={5}
          autoFocus
          // temp fix
          h={150}
          {...props}
        />
        <FieldError message={error?.errorMessage} />
      </Fieldset>
    </Theme>
  )
}

import { AlertDialog, Button, H1, Heading, Paragraph, Text, XStack, YStack } from '@mps/ui'
import { Platform } from 'react-native'

import { useSupabase } from '@mps/pro-app/utils/supabase/useSupabase'
// import { useSignIn } from '@mps/pro-app/utils/clerk'
import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { Link } from 'solito/link'
import { parseNextPath, useRouter } from 'solito/router'
import { Logo } from '../../core/components/Logo'
import { Layout } from '../../core/components/layouts/Layout'
import { SignInForm } from '../components/SignInForm'

// const SignInSchema = z.object({
//   email: formFields.text.email().describe('Email // Entrez votre email'),
//   password: formFields.text.min(6).describe('Mot de passe // Entrez votre mot de passe'),
// })

const formErrorMessageByCode: Record<string, string> = {
  form_password_incorrect: 'Le mot de passe est incorrect',
  form_identifier_not_found: 'L’adresse email est inconnue',
}

const formErrorMessageByMessage: Record<string, string> = {
  'Invalid login credentials': 'L’adresse email ou le mot de passe est incorrect',
}

type ApiError = { code: string; message: string }

export function SignInScreen() {
  const { push, replace } = useRouter()
  const supabase = useSupabase()
  const [showDebugInfos, setShowDebugInfos] = useState(false)
  // const { isLoaded, signIn, setSession, ...rest } = useSignIn()
  const [formError, setFormError] = useState<ApiError | null>()

  console.log({ formError })

  // @ts-ignore
  const handleEmailSignInWithPress = async ({ email, password }) => {
    try {
      const { error, data } = await supabase.auth.signInWithPassword({
        email,
        password,
      })

      console.log({ error, data })

      if (error) {
        throw new Error(error.message)
      }
      console.log('signIn', { data })

      if (data?.session && data?.user) {
        if (Platform.OS === 'web') {
          const redirectUrl = new URLSearchParams(window.location.search).get('vers') || '/'
          console.log({ redirectUrl })
          replace(redirectUrl)
        } else {
          replace('/')
        }
      }
    } catch (error: any) {
      console.log({ error, errorMessage: error?.message, errorCode: error?.code })
      setFormError((error?.errors?.[0] as ApiError) || error)
    }
  }

  const hasError = !!(formError?.code || formError?.message)
  console.log({ hasError })

  return (
    <Layout centerContent bg="$backgroundStrong" hasHeader={false}>
      <YStack
        maxWidth={500}
        ai="center"
        jc="space-around"
        height={Platform.OS === 'web' ? '100vh' : '100%'}
      >
        <YStack
          space="$2"
          ai="center"
          onLongPress={() => {
            if (Platform.OS !== 'web') {
              setShowDebugInfos(true)
            }
          }}
        >
          <Logo size={60} />
          <Heading fontWeight="800">Mon Petit Soin</Heading>
        </YStack>

        <YStack>
          <SignInForm onSubmit={handleEmailSignInWithPress} />
        </YStack>

        {showDebugInfos && (
          <AlertDialog open={showDebugInfos}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay
                key="overlay"
                animation="quick"
                style={{ opacity: 0.5 }}
                enterStyle={{ o: 0 }}
                exitStyle={{ o: 0 }}
              />
              <AlertDialog.Content
                bordered
                elevate
                key="content"
                animation={[
                  'quick',
                  {
                    opacity: {
                      overshootClamping: true,
                    },
                  },
                ]}
                enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
                exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
                x={0}
                scale={1}
                opacity={1}
                y={0}
              >
                <YStack space>
                  <YStack space>
                    <H1>Debug infos:</H1>
                    <XStack>
                      <Text>STAGE: {process.env.STAGE}</Text>
                    </XStack>
                    <XStack>
                      <Text>NEXT_PUBLIC_SITE_URL: {process.env.NEXT_PUBLIC_SITE_URL}</Text>
                    </XStack>
                    <XStack>
                      <Text>NEXT_PUBLIC_TRPC_API_URL: {process.env.NEXT_PUBLIC_TRPC_API_URL}</Text>
                    </XStack>
                    <XStack>
                      <Text>NEXT_PUBLIC_SUPABASE_URL: {process.env.NEXT_PUBLIC_SUPABASE_URL}</Text>
                    </XStack>
                    <XStack>
                      <Text>
                        NEXT_PUBLIC_SUPABASE_ANON_KEY: {process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY}
                      </Text>
                    </XStack>
                  </YStack>

                  <XStack space="$3" jc="center">
                    <AlertDialog.Action asChild>
                      <Button onPress={() => setShowDebugInfos(false)}>Ok</Button>
                    </AlertDialog.Action>
                  </XStack>
                </YStack>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog>
        )}

        {hasError && (
          <AlertDialog open={hasError}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay
                key="overlay"
                animation="quick"
                style={{ opacity: 0.5 }}
                enterStyle={{ o: 0 }}
                exitStyle={{ o: 0 }}
              />
              <AlertDialog.Content
                bordered
                elevate
                key="content"
                animation={[
                  'quick',
                  {
                    opacity: {
                      overshootClamping: true,
                    },
                  },
                ]}
                enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
                exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
                x={0}
                scale={1}
                opacity={1}
                y={0}
              >
                <YStack space>
                  {(formError?.code ?? formError?.message) && (
                    <AlertDialog.Title fontSize="$8" textAlign="center">
                      {formErrorMessageByCode?.[formError?.code] ??
                        formErrorMessageByMessage?.[formError?.message] ??
                        formError?.message}
                    </AlertDialog.Title>
                  )}
                  {formError?.code === 'form_identifier_not_found' && (
                    <AlertDialog.Description>
                      <Text fontFamily="$body" key={formError.code}>
                        Vous n&apos;avez pas de compte ?{' '}
                        <Link href="/inscription" style={{ flexDirection: 'row' }}>
                          <Text fontFamily="$body" textDecorationLine="underline">
                            Créer un compte
                          </Text>
                        </Link>
                      </Text>
                    </AlertDialog.Description>
                  )}

                  <XStack space="$3" jc="center">
                    <AlertDialog.Action asChild>
                      <Button onPress={() => setFormError(null)}>Ok</Button>
                    </AlertDialog.Action>
                  </XStack>
                </YStack>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog>
        )}

        <YStack space="$4" ai="center">
          <ForgotPasswordLink />
          <SignUpLink />
          {/* <Link href="/inscription/verification-email">
          </Link> */}
          {/* <XStack>
            <Text pr="$2" fontFamily="$body" numberOfLines={1}>
              Vous n&apos;avez pas de compte ?
            </Text>
            <Link href="/inscription" style={{ flexDirection: 'row' }}>
              <Text fontFamily="$body" textDecorationLine="underline">
                Créer un compte
              </Text>
            </Link>
          </XStack> */}
          {Platform.OS === 'web' && (
            <Link href="https://monpetitsoin.fr/demande/soins">
              <Paragraph textAlign="center" theme="alt1">
                Vous cherchez une infirmière ?{' '}
                <Text textDecorationLine="underline">Prendre rendez-vous</Text>
              </Paragraph>
            </Link>
          )}
        </YStack>
      </YStack>
    </Layout>
  )
}

const SignUpLink = () => {
  // const email = useWatch<z.infer<typeof SignInSchema>>({ name: 'email' })
  const email = null

  return (
    <Link href={`/inscription?${new URLSearchParams(email ? { email } : undefined).toString()}`}>
      <Paragraph textAlign="center" theme="alt1">
        Vous n&apos;avez pas de compte ? <Text textDecorationLine="underline">Inscription</Text>
      </Paragraph>
    </Link>
  )
}

const ForgotPasswordLink = () => {
  // const email = useWatch<z.infer<typeof SignInSchema>>({ name: 'email' })
  const email = null

  return (
    <Link
      href={`/reinitialisation-mot-de-passe?${new URLSearchParams(email ? { email } : undefined)}`}
    >
      <Paragraph mt="$1" theme="alt2" textDecorationLine="underline">
        Vous avez oublié votre mot de passe ?
      </Paragraph>
    </Link>
  )
}

import { useFormState } from 'react-hook-form'
import { ButtonProps } from 'tamagui'
import { Button } from './Button/Button'

// hack to prevent it from breaking on the server
const useIsSubmitting = () => {
  try {
    return useFormState().isSubmitting
  } catch (error) {
    console.log(error)
    return false
  }
}
/**
 * created to be used in forms
 * will show loading spinners and disable submission when already submitting
 */
export const SubmitButton = (props: ButtonProps) => {
  const isSubmitting = useIsSubmitting()
  console.log({ isSubmitting })

  return (
    <Button
      animation={isSubmitting ? null : 'bouncy'}
      hoverStyle={{ scale: 0.98 }}
      isLoading={isSubmitting}
      disabled={isSubmitting}
      {...props}
    />
  )
}
